.navbar {
    z-index: 1000;
    height: 60px;
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: rgba(0, 0, 0, 0.9);
                &.active {
                    color: rgba(0, 0, 0, 0.55);
                }
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| Primary Navigation
|--------------------------------------------------------------------------
*/
#mainNav {
    //background-color: $ppg-color-primary;
    width: 100%!important;
    padding-top: 0;
    padding-bottom: 0;
    height: 70px;
    z-index: 20!important;
    -webkit-box-shadow: 0 6px 11px -5px rgba(153,153,153,1);
    -moz-box-shadow: 0 6px 11px -5px rgba(153,153,153,1);
    box-shadow: 0 6px 11px -5px rgba(153,153,153,1);

    i {
        &.cap {
            vertical-align: middle;
            line-height: inherit;
            margin-right: 8px;
        }
    }

    /*
    |--------------------------------------------------------------------------
    | Navbar Toggler
    |--------------------------------------------------------------------------
    */
    &.navbar-light {
        .navbar-toggler {
            border-color: rgba(255,255,255,1);

            &:focus {
                outline: 0 none;
            }
        }
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }


    /*
    |--------------------------------------------------------------------------
    | Navbar
    |--------------------------------------------------------------------------
    */
    .navbar-collapse {

        .navbar-close {
            display: none;
        }

        .navbar-nav {

            .nav-item {

                .nav-link {
                    //color: #ffffff;
                    font-weight: 700;
                    text-transform: uppercase;
                    height: 70px;
                    line-height: 38px;
                    padding: 16px 12px;

                    &.active {
                        color: $ppg-color-primary;
                    }
                }

                &.dropdown {

                    .dropdown-menu {
                        margin-top: 0;
                        padding-top: 0;
                        padding-bottom: 0;

                        li {
                            border-bottom: 1px solid $lightgrey;
                        }

                        .dropdown-item {
                            padding-top: 14px;
                            padding-bottom: 14px;
                            border-bottom: 1px solid $lightgrey;

                            &:hover {
                                color: $ppg-color-primary;
                                background-color: $white;
                                transition: 0.3s;
                            }

                            &:last-of-type {
                                border-bottom: none;
                            }

                            &.active {
                                background-color: $white;
                                color: inherit;

                                &:hover {
                                    color: $ppg-color-primary;
                                }
                            }

                        }
                    }
                }

            }
        }
    }
}

@media only screen and (min-width: 992px) {

    #mainNav {

        .navbar-collapse {

            .navbar-nav {

                .nav-item {

                    .dropdown-toggle {
                        &:after {
                            display: none;
                        }
                    }

                    .dropdown-side {
                        &:after {
                            margin-bottom: -1px;
                            display: inline-block;
                            -ms-transform: rotate(-90deg); /* IE 9 */
                            -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
                            transform: rotate(-90deg);
                        }
                    }

                    .dropdown-menu {
                        .mobile {
                            display: none;
                        }
                    }
                }

                // TODO: Test
                li {
                    position: relative;

                    &:hover {
                        > ul {
                            display: block;
                        }
                    }
                }

                ul {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    min-width: 250px;
                    display: none;

                    &.dropdown-menu-end {
                        left: unset;
                        right: 0;
                    }

                    ul {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        min-width: 250px;
                        display: none;
                    }
                }

                .dropdown {

                    &.full {
                        position: static;

                        .dropdown-menu {
                            left: 0;
                            right: 0;
                            border-top: 0 none;

                            li {
                                &.big {
                                    display: block;
                                    border-bottom: 0 none;
                                }
                            }

                            .container {
                                position: relative;

                                .mega-menu {
                                    position: absolute;
                                    z-index: 999;
                                    max-height: 500px;
                                    overflow-y: auto;
                                    overflow-x: hidden;
                                    top: 0;
                                    left: 15px;
                                    right: 15px;
                                    border-left: 1px solid $lightgrey;
                                    border-right: 1px solid $lightgrey;
                                    border-bottom: 1px solid $lightgrey;
                                    box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
                                    background-color: #ffffff;
                                    padding: 30px;

                                    .top-reward-categories {

                                        a {
                                            color: inherit;
                                            text-decoration: none;
                                        }

                                        h1 {
                                            font-size: 1.2rem;
                                            margin-top: 0;
                                            margin-bottom: .7rem;
                                            color: inherit;

                                            &:hover {
                                                color: $grey;
                                            }
                                        }

                                        img {
                                            border-top: 2px solid black;
                                            width: 100%;
                                            height: auto;
                                            max-height: 150px;
                                            object-fit: cover;
                                        }
                                    }

                                    .reward-categories {
                                        padding-top: 4px;

                                        h1 {
                                            color: inherit;
                                            padding-top: 40px;
                                            font-size: 1.2rem;
                                            border-bottom: 1px solid black;
                                            margin-top: 0;
                                            margin-bottom: .7rem;
                                        }

                                        a {
                                            text-decoration: none;
                                            color: inherit;

                                            &:hover {
                                                color: $grey;
                                            }
                                        }
                                    }

                                    .product-categories {

                                        a {
                                            color: inherit;
                                            text-decoration: none;
                                        }

                                        h1  {
                                            padding-top: 40px;
                                            font-size: 1.2rem;
                                        }

                                        img {
                                            border-top: 2px solid black;
                                            width: 100%;
                                            height: auto;
                                            max-height: 166px;
                                            object-fit: cover;
                                        }

                                        &:first-of-type {
                                            h1 {
                                                padding-top: 0;
                                            }
                                        }

                                    }

                                    .owl-carousel {
                                        &.owl-hidden {
                                            opacity: 1;
                                        }

                                        .owl-stage {
                                            display: flex;
                                        }
                                        .owl-item {

                                            img {
                                                /*       width: auto;
                                                       height: 380px;
                                                       margin: 0 auto;*/
                                            }
                                        }
                                    }


                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

/* hover dropdown menus */
/*@media only screen and (max-width: 991px) {

    #mainNav {

        .navbar-collapse {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            min-width: 250px;
            z-index: 10;
            color: black;
            background-color: #ffffff;
            overflow: scroll;
            overflow-x: hidden;
            overflow-y: auto;

            &.collapsing {
                height: auto !important;
                transition: all 0.2s ease;
                left: -250px;
            }
            &.show {
                transition: all 0.2s ease;
                left: 0;
            }

            .navbar-close {
                display: inherit;
                text-align: left;
                background-color: #ffffff;
                border-bottom: 1px solid $lightgrey;

                span {
                    font-size: 1.2rem;
                }
            }

            .navbar-nav {

                .nav-item {

                    .nav-link {
                        height: 62px;
                        line-height: 30px;
                        color: black;
                        border-bottom: 1px solid $lightgrey;

                        &:hover {
                            color: $grey;
                            transition: 0.2s;
                        }
                    }
                }

                .dropdown-submenu {
                    position: relative;
                    border-bottom: 0 none!important;

                    .dropdown-menu {
                        top: 0;
                        left: 100%;
                        margin-top: -1px;

                        .dropdown-item {
                            padding-left: 36px;
                        }
                    }
                }

                .dropdown {

                    li {
                        &.big {
                            display: none;
                        }
                    }
                }
            }
        }

        .nav-backdrop {
            background: rgba(22, 22, 22, 0.5) !important;
            position: fixed;
            display:none;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
        }
    }
}*/

.login-popover {
    display: none;
}

.nav-tabs-styled {

    .nav-tabs {
        border-bottom: 1px solid #dddddd;

        .nav-item {

            &:first-of-type {
                .nav-link {
                    border-left: 1px solid transparent;
                }
            }

            .nav-link {
                color: $white;
                background-color: #B9B9B9;
                font-weight: 700;
                border-left: 1px solid $white;
                border-top-left-radius: 0;
                border-top-right-radius: 0;

                &.active {
                    background-color: #3097D1;
                    border-color: transparent;
                    border-left: 1px solid $white;
                }
            }
        }
    }
}




/*
|--------------------------------------------------------------------------
| Nav Icons (Cart, Account, News)
|--------------------------------------------------------------------------
*/
#top-icon-wrapper {
    position: absolute;
    right: 15px;
    top: 25px;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    .top-icon {
        color: #000;
        text-decoration: none;
        display: inline-block;
        padding-left: 15px;

        .icon-text {
            margin-right: 20px;

            &.nm {
                margin-right: 0;
            }
        }

        i {
            display: inline-block;
            width: 30px;
            position: relative;
            height: 20px;

            &:before {
                position: absolute;
                font-size: 1.7rem;
                top: 0;
            }

            .amount-dot {
                position: absolute;
                margin-top: -24px;
                margin-left: -15px;
                background: #e3342f;
                color: #ffffff;
                text-align: center;
                vertical-align: middle;
                min-width: 25px;
                height: 25px;
                font-size: 1rem;
                padding-top: 5px;
                padding-left: 5px;
                padding-right: 5px;
                -webkit-transition: -webkit-transform 0.5s;
                transition: transform 0.5s, -webkit-transform 0.5s;
                -webkit-transform: scale(1);
                transform: scale(1);
                font-family: "Nunito", sans-serif;

                &:after {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 0 0 10px;
                    border-color: #e3342f transparent transparent transparent;
                    position: absolute;
                    display: block;
                    right: 0;
                    bottom: -6px;
                    -webkit-transition: bottom 0.3s;
                    transition: bottom 0.3s;
                }

                &.flex-font {
                    max-width: 110px;
                    text-overflow: ellipsis;
                }

                &.gray {
                    background: #999999;

                    &:after {
                        border-color: #999999 transparent transparent transparent !important;
                    }
                }
            }
        }
    }
}
