// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

$primary: $ppg-color-primary;
$secondary: $ppg-color-secondary;
$danger: $ppg-color-paris-pink;

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// FontAwesome
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';

// DataTables
@import "~datatables.net-bs5/css/dataTables.bootstrap5.min.css";

// Dropzone
@import "~dropzone/dist/dropzone.css";

@import 'webfonts/suisse';

@import "datepicker";
@import "navigation";
@import "backoffice";
@import "form";
@import "scaffold_tarpaulin";
@import "seminar";

main {
    height: calc(100vh - 70px);
    overflow: auto;
}

ul.pagination {
    li.page-item:first-child {
        margin-left: auto;
    }
}

.brand-logo {
    width: 70px;
    height: auto;
}

.search-reset {
    color: inherit;
}


.amount-indicator {
    content: " ";
    position: absolute;
    background-color: $danger;
    color: $ppg-color-delicate-white;
    border-radius: 100%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 1rem;
    left: 1rem;
    font-size: 0.7rem;
    padding: 0.25rem;
    font-weight: 700;
    line-height: 1.1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.resize-none {
    resize: none;
}

.left-unset {
    left: unset !important;
}

.top-unset {
    top: unset !important;
}

.right-unset {
    right: unset !important;
}

.bottom-unset {
    bottom: unset !important;
}

.w-0 {
    width: 0;
}

.flex-0 {
    flex: 0;
}

.flex-1 {
    flex: 1;
}

@media (min-width: 576px) {
    .w-sm-auto {
        width: auto !important;
    }
}

@media (min-width: 768px) {
    .w-md-auto {
        width: auto !important;
    }
    .mt-md-3 {
        margin-top: 1rem !important;
    }
}

@media (min-width: 991px) {
    .mt-lg-0 {
        margin-top: 0 !important;
    }
}

