.scaffold-tarpaulin {

    &.order-search {

        .search-reset {
            color: inherit;
        }

        #scaffoldTarpaulinList {
            display: none;

            .show-details {
                color: inherit;
            }
        }
    }
}


.scaffold-tarpaulin-item {
    border-bottom: 1px solid $lightgrey;
    padding-bottom: 16px;
    padding-top: 16px;

    .image-wrapper {
        flex-basis: 100px;

        .image {

            border: 1px solid darken($lightgrey, 10%);

            img {
                width: 100%;
                max-width: 100px;
                height: auto;
            }
        }

        @media (max-width: 575px) {
            flex-basis: 20%;
        }
    }

    .description {
        padding-left: 25px;
        padding-right: 25px;
        flex-direction: column;
        flex: 1;

        a {
            text-decoration: none;
            color: inherit;
        }

        .title {
            font-size: 1.2rem;
        }

        .price {
            padding-top: 10px;
            padding-left: 0!important;
        }

        @media (max-width: 575px) {
            flex-basis: 80%;
            padding-left: 10px;
        }
    }

    .amount {
        padding-right: 50px;

        input {
            margin-left: 20px;
            width: 80px;
            background: #f6f6f6;
            border: none;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @media (max-width: 991px) {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(100% - 50px);
            padding-right: 10px;
        }
    }

    .price {
        flex-basis: 140px;
        color: $primary;
        font-weight: normal;
        font-size: 1.2rem;

        @media (max-width: 991px) {
            flex: 1 1 100%;
            justify-content: flex-start!important;
            padding-left: 125px;
        }
    }

    .options {
        flex-basis: 50px;

        i {
            cursor: pointer;

            &:first-of-type {
                margin-right: 10px;
            }

            &.fa-check-circle {
                color: $green;
            }

            &.fa-times-circle {
                color: $red;
            }
        }
    }

    &.order-total {
        border-bottom: none;

        .total-title {
            font-size: 1.2rem;
            font-weight: 400;
            padding-right: 20px;
        }
    }

    @media (max-width: 575px) {
        .description {
            .title {
                font-size: 1rem;
            }
            .text-muted {
                font-size: 0.7rem;
            }

        }

        .amount, .price, .options {
            font-size: 0.8rem;
        }
    }
}

.documents {

    .table {
        thead {
            th {
                &.view {
                    width: 50px;
                }
                &.extension {
                    width: 100px;
                }
                &.size {
                    width: 100px;
                }
                &.remove {
                    width: 100px;
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;

                    &.view {
                        font-size: 1.2rem;
                    }

                    .input-group {
                        position: relative;

                        .data-table-option {
                            position: absolute;
                            right: 106px;

                            &.error, &.check  {
                                padding-top: 2px;
                                padding-bottom: 0;
                            }
                        }

                        button {
                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
    }
}
