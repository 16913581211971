#backoffice {
    .info-link {
        text-decoration: none;
        &:hover {
            .info {
                box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
            }
        }

        .info {
            display: table;
            height: 100%;
            width: 100%;
            border: 1px solid #ddd;
            padding: 15px 0;

            h1 {
                font-size: 1.6rem;
                text-decoration: underline;
                margin-bottom: 1rem;
            }

            ul {
                padding-left: 20px;
            }

            >div {
                display: table-cell;

                &:first-of-type {
                    padding: 0 15px 0 30px;
                    vertical-align: middle;
                    width: 35px;

                    i {
                        color: gray;
                        font-size: 2rem;
                    }
                }

                &:last-of-type {
                    padding-left: 15px;
                }

                a {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }
}
