/* Light */
@font-face {
    font-family: 'Suisse';
    font-style: normal;
    font-weight: 300;
    src: url('../webfonts/suisse/SuisseIntl-Light.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/suisse/SuisseIntl-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/suisse/SuisseIntl-Light.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Light.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/suisse/SuisseIntl-Light.svg#SuisseIntl') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Suisse';
    font-style: italic;
    font-weight: 300;
    src: url('../webfonts/suisse/SuisseIntl-LightItalic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/suisse/SuisseIntl-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/suisse/SuisseIntl-LightItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/suisse/SuisseIntl-LightItalic.svg#SuisseIntl') format('svg'); /* Legacy iOS */
}


/* Medium */
@font-face {
    font-family: 'Suisse';
    font-style: normal;
    font-weight: normal;
    src: url('../webfonts/suisse/SuisseIntl-Medium.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/suisse/SuisseIntl-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/suisse/SuisseIntl-Medium.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Medium.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/suisse/SuisseIntl-Medium.svg#SuisseIntl') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: 'Suisse';
    font-style: italic;
    font-weight: normal;
    src: url('../webfonts/suisse/SuisseIntl-MediumItalic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/suisse/SuisseIntl-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/suisse/SuisseIntl-MediumItalic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/suisse/SuisseIntl-MediumItalic.svg#SuisseIntl') format('svg'); /* Legacy iOS */
}

/* Regular */
@font-face {
    font-family: 'Suisse';
    font-style: normal;
    font-weight: 500;
    src: url('../webfonts/suisse/SuisseIntl-Regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/suisse/SuisseIntl-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/suisse/SuisseIntl-Regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Regular.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/suisse/SuisseIntl-Regular.svg#SuisseIntl') format('svg'); /* Legacy iOS */
}

/* Semi Bold */
@font-face {
    font-family: 'Suisse';
    font-style: normal;
    font-weight: 600;
    src: url('../webfonts/suisse/SuisseIntl-SemiBold.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/suisse/SuisseIntl-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/suisse/SuisseIntl-SemiBold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-SemiBold.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-SemiBold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/suisse/SuisseIntl-SemiBold.svg#SuisseIntl') format('svg'); /* Legacy iOS */
}

/* Bold */
@font-face {
    font-family: 'Suisse';
    font-style: normal;
    font-weight: bold;
    src: url('../webfonts/suisse/SuisseIntl-Bold.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/suisse/SuisseIntl-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/suisse/SuisseIntl-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Bold.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/suisse/SuisseIntl-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/suisse/SuisseIntl-Bold.svg#SuisseIntl') format('svg'); /* Legacy iOS */
}
