// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'SuisseIntl', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$ppg-color-primary: #0078A9;
$ppg-color-secondary: #0033A0;

$ppg-color-kingsfisher: #0078A9;
$ppg-color-florentine-lapis: #0033A0;
$ppg-color-high-dive: #3EC7F4;
$ppg-color-leap-frog: #00B149;
$ppg-color-mirabella: #8F1A95;
$ppg-color-paris-pink: #D0006F;
$ppg-color-chinese-lantern: #FF7C13;
$ppg-color-fall-gold: #FFB81C;
$ppg-color-black-magic: #000000;
$ppg-color-volcanic-ash: #666666;
$ppg-color-gray-frost: #BCBCBC;
$ppg-color-delicate-white: #FFFFFF;

$grey: $ppg-color-volcanic-ash;
$lightgrey: $ppg-color-gray-frost;
