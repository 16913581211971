.form-group {
    margin-bottom: 1rem;
}
input:not([type=checkbox]),select, textarea {
    &:required {
        border-left: 5px solid $danger;
    }
}
input.numeric, input.numericInteger{
    text-align: right;
}
.chat-message {
    .chat-message-files {
        position: relative;
    }
    .delete-files {
        margin-right: 1.5rem;
        align-self: start;
        font-size: 1.25rem;
        position: relative;
        bottom: 0.25rem;
        cursor: pointer;
    }
    .fa-paperclip {
        font-size: 1.5rem;
        transform: rotate(-90deg);
    }
}
.messages {
    display: flex;
    flex-direction: column;
    align-items: start;

    .message {
        max-width: 80%;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        color: $ppg-color-black-magic;

        a {
            color: $ppg-color-black-magic;
        }

        .files {
            display: flex;
            flex-wrap: wrap;

            .file {
                &:nth-child(odd) {
                    margin-right: 0.5rem;
                }
                &:nth-child(even) {
                    margin-left: 0.5rem;
                }
                border: 2px solid;
                border-radius: 0.5rem;
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
                padding: 2rem;
                flex-basis: calc(50% - 0.5rem);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.own {
            align-self: end;
            background-color: $ppg-color-leap-frog;
            color: $ppg-color-delicate-white;

            a {
                color: $ppg-color-delicate-white;
            }
        }

        &.unread {
            animation: unread-fader 6s normal;
        }

        .info {
            font-size: 0.8rem;
        }
    }
}

.form-control {
    padding: 0.375rem 0.75rem !important;
    font-size: 0.9rem !important;
    line-height: 1.6 !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

@keyframes unread-fader
{
    0% {
        //box-shadow: 0 0 0 0.25rem rgba(0, 120, 169, 0.30);
        box-shadow: 0 0 0 0.25rem #00B14988;
    }
    50% {
        //box-shadow: 0 0 0 0.25rem rgba(0, 120, 169, 0.15);
        box-shadow: 0 0 0 0.25rem #00B14944;
    }
    100% {
        //box-shadow: 0 0 0 0.25rem rgba(0, 120, 169, 0);
        box-shadow: 0 0 0 0.25rem #00B14900;
    }
}
